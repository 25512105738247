<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-alert outlined type="success" text>
            Masukkan Kode Daftar Ulang yang telah Diberikan, Jika berhasil isi
            Form sesuai dengan data kependudukan yang dimiliki
          </v-alert>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="cari.kode_reg" label="Kode Daftar Ulang" clearable></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn @click="showData" class="mx-2" fab dark small color="primary">
            <v-icon dark>mdi-database-search</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-col cols="12">
        <v-alert outlined color="purple">
          <div class="title">Syarat Daftar Ulang</div>
          <div>
            <ul>
              <li>
                Melampirkan Ijasah atau SKHU yang telah dilegalisir = 2 lembar
              </li>
              <li>
                Pas foto warna ukuran 3X4 (berjas dan berdasi) sebanyak 3 (tiga)
                lembar
              </li>
              <li>
                Melampirkan fotocopy KTP dan Kartu Keluarga
              </li>
              <li>
                Melampirkan foto copy ijasah dan transkip akademik diploma yang
                telah dligalisir =1 lembar (bagi mahasiswa alih jenjang/anvulan)
                = 2 lembar
              </li>
              <li>
                Melampirkan Surat Keterangan Pindah dari perguruan tinggi asal
                dengan akreditasi setara atau lebih tinggi (bagi mahasiswa
                pindahan) =2 lembar
              </li>
              <li>
                Melampirkan Surat Keterangan Catatan Kepolisian (SKCK) Asli yang
                masih berlaku
              </li>
              <li>Melampirkan Surat Keterangan Kesehatan</li>
              <li>
                Melampirkan Surat Pernyataan yang telah ditanda tangani diatas
                materai Rp 6.000,-
              </li>
              <li>
                Melampirkan foto copy Kwitansi telah membayar Biaya
                Herregistrasi, Administrasi BANK dan KTM serta Jaket Almamater,
                Biaya DPP, Biaya UKM, serta Biaya SPP Tetap dan Biaya SKS
                (kwitansi dapat diambil di BAAK Unikarta lantai satu &
                Pembayaran di Bank SYARIAH MANDIRI)
              </li>
              <li>
                Formulir ini harap segera dikembalikan Kepada Panitia sesuai
                masa daftar ulang yang telah ditentukan.
              </li>
            </ul>
          </div>
        </v-alert>
      </v-col>
    </v-container>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-form v-model="valid">
        <v-card class="overflow-hidden" color="purple lighten-1" dark>
          <v-toolbar flat color="purple">
            <v-icon>mdi-account</v-icon>
            <v-toolbar-title class="font-weight-light">From Profile Daftar Ulang Peserta</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="purple darken-3" fab small @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-subheader>Peserta Pemitan Prodi</v-subheader>
          <v-card-text>
            <v-container><v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.nama_program_studi" label="Program Studi" disabled></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.kelas" label="Kelas" disabled></v-text-field>
                </v-col>
              </v-row> </v-container></v-card-text>
          <v-subheader>Peserta Profil</v-subheader>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.nik" label="Nomor Induk Kependudukan"
                    :rules="[rules.required, rules.min, rules.counter]" hint="At least 16 characters" counter
                    maxlength="16"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.nm_pst" label="Nama Peserta"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.jenis_kelamin" label="Jenis Kelamin"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="agamais" color="white" item-text="nm_agama" item-value="id_agama"
                    label="Agama" v-model="updateItem.agama" :rules="[rules.required]"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.tempat_lahir" label="Tempat Lahir"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <!-- <v-text-field
                  v-model="updateItem.tanggal_lahir"
                  label="Tanggal Lahir"
                ></v-text-field> -->
                  <v-menu ref="tglPst" v-model="tglPst" :close-on-content-click="false"
                    :return-value.sync="updateItem.tanggal_lahir" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="updateItem.tanggal_lahir" :rules="[rules.required]" label="Tanggal Lahir"
                        prepend-icon="fas fa-calendar-alt" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="updateItem.tanggal_lahir" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="tglPst = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.tglPst.save(updateItem.tanggal_lahir)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.no_telp" label="No HP Aktiv"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.email" label="Email"
                    :rules="[rules.required, rules.email]"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-subheader>Profil Ayah</v-subheader>
          <v-card-text>
            <v-container><v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.nm_ayah" label="Nama Ayah Kandung"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <!-- <v-text-field
                  v-model="updateItem.tanggal_lahir_ayah"
                  label="Tanggal Lahir Ayah"
                ></v-text-field> -->
                  <v-menu ref="tglAyah" v-model="tglAyah" :close-on-content-click="false"
                    :return-value.sync="updateItem.tanggal_lahir_ayah" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="updateItem.tanggal_lahir_ayah" label="Tanggal Lahir Ayah"
                        prepend-icon="fas fa-calendar-alt" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="updateItem.tanggal_lahir_ayah" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="tglAyah = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="
                        $refs.tglAyah.save(updateItem.tanggal_lahir_ayah)
                      ">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="jenjang" color="white" item-text="jenjang_pendidikan"
                    item-value="kode_jenjang_pendidikan" label="Jenjang Pendidikan Ayah"
                    v-model="updateItem.id_jenjang_pendidikan_ayah" :rules="[rules.required]"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="pekerjaans" color="white" item-text="pekerjaan" item-value="kode_pekerjaan"
                    label="Pekerjaan Ayah" v-model="updateItem.id_pekerjaan_ayah"
                    :rules="[rules.required]"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="penghasilans" color="white" item-text="nm_penghasilan"
                    item-value="id_penghasilan" label="Penghasilan Ayah" v-model="updateItem.id_penghasilan_ayah"
                    :rules="[rules.required]"></v-autocomplete>
                </v-col>
              </v-row> </v-container></v-card-text>
          <v-subheader>Profil Ibu</v-subheader>
          <v-card-text>
            <v-container><v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.nm_ibu" label="Nama Ibu Kandung"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <!-- <v-text-field
                  v-model="updateItem.tanggal_lahir_ibu"
                  label="Tanggal Lahir Ibu"
                ></v-text-field> -->
                  <v-menu ref="tglIbu" v-model="tglIbu" :close-on-content-click="false"
                    :return-value.sync="updateItem.tanggal_lahir_ibu" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="updateItem.tanggal_lahir_ibu" label="Tanggal Lahir Ibu"
                        prepend-icon="fas fa-calendar-alt" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="updateItem.tanggal_lahir_ibu" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="tglIbu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.tglIbu.save(updateItem.tanggal_lahir_ibu)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="jenjang" color="white" item-text="jenjang_pendidikan"
                    item-value="kode_jenjang_pendidikan" label="Jenjang Pendidikan Ibu"
                    v-model="updateItem.id_jenjang_pendidikan_ibu" :rules="[rules.required]"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="pekerjaans" color="white" item-text="pekerjaan" item-value="kode_pekerjaan"
                    label="Pekerjaan Ibu" v-model="updateItem.id_pekerjaan_ibu"
                    :rules="[rules.required]"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="penghasilans" color="white" item-text="nm_penghasilan"
                    item-value="id_penghasilan" label="Penghasilan Ibu" v-model="updateItem.id_penghasilan_ibu"
                    :rules="[rules.required]"></v-autocomplete>
                </v-col> </v-row></v-container></v-card-text>
          <v-subheader>Profil Alamat</v-subheader>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.jalan" label="Nama Jalan" :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.rt" label="RT" :rules="[rules.required]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="updateItem.kelurahan" label="Kelurahan/Desa"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="wil" color="white" item-text="nm_wil" item-value="id_wil" label="Kecamatan"
                    v-model="updateItem.kecamatan" :rules="[rules.required]">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-subheader>Perlengkapan</v-subheader>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="almamater" color="white" item-text="almamater" item-value="almamater"
                    label="Ukuran Almamater" v-model="updateItem.almamater" :rules="[rules.required]">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete :items="kaos" color="white" item-text="kaos" item-value="kaos" label="Ukuran Kaos"
                    v-model="updateItem.kaos" :rules="[rules.required]">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" @click="seveData" color="success">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "registrasiUlang",
  data: () => ({
    valid: false,
    cari: { kode_reg: "" },
    dialog: false,
    tglIbu: false,
    tglAyah: false,
    tglPst: false,
    updateItem: {
      nm_pst: "",
      tempat_lahir: "",
      tanggal_lahir: new Date().toISOString().substr(0, 10),
      nik: "",
      jenis_kelamin: "",
      agama: "",
      no_telp: "",
      email: "",
      nm_ayah: "",
      tanggal_lahir_ayah: new Date().toISOString().substr(0, 10),
      id_jenjang_pendidikan_ayah: "",
      id_pekerjaan_ayah: "",
      id_penghasilan_ayah: "",
      nm_ibu: "",
      tanggal_lahir_ibu: new Date().toISOString().substr(0, 10),
      id_jenjang_pendidikan_ibu: "",
      id_pekerjaan_ibu: "",
      id_penghasilan_ibu: "",
      jalan: "",
      kelurahan: "",
      rt: "",
      kecamatan: "",
      kaos: "",
      almamater: ""
    },
    rules: {
      required: value => !!value || "Required.",
      min: value => (value && value.length >= 16) || "Min 16 characters",
      counter: value => (value && value.length <= 16) || "Max 16 characters",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    },
    almamater: ["XXL", "XL", "L", "M", "S"],
    kaos: ["XXL", "XL", "L", "M", "S"]
  }),
  created() {
    this.loadAgama();
    this.loadJenjang();
    this.loadPekerjaan();
    this.loadPenghasilan();
    this.loadWilayah();
  },
  computed: {
    ...mapGetters("auth", ["userInfo"]),
    ...mapGetters("regUlang", [
      "getDaful",
      "wilayah",
      "agama",
      "jenjangPen",
      "pekerjaan",
      "penghasilan"
    ]),
    wil() {
      return Object.keys(this.wilayah).map(k => {
        let obj = this.wilayah[k];
        return obj;
      });
    },
    agamais() {
      return Object.keys(this.agama).map(k => {
        let obj = this.agama[k];
        return obj;
      });
    },

    jenjang() {
      return Object.keys(this.jenjangPen).map(k => {
        let obj = this.jenjangPen[k];
        return obj;
      });
    },

    pekerjaans() {
      return Object.keys(this.pekerjaan).map(k => {
        let obj = this.pekerjaan[k];
        return obj;
      });
    },

    penghasilans() {
      return Object.keys(this.penghasilan).map(k => {
        let obj = this.penghasilan[k];
        return obj;
      });
    }
  },
  methods: {
    formData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    ...mapActions("regUlang", [
      "loadWilayah",
      "loadAgama",
      "loadJenjang",
      "loadPekerjaan",
      "loadPenghasilan"
    ]),
    showData() {
      let kode = {
        id_pst: this.userInfo.id,
        kode_reg: this.cari.kode_reg
      };
      this.$store
        .dispatch("regUlang/loadDaful", kode)
        .then(() => {
          this.updateItem = Object.assign({}, this.getDaful);
          this.dialog = true;
        })
        .catch(err => {
          this.$alert(err.response.data, "Error", "error");
        });
    },
    seveData() {
      this.$store.dispatch("regUlang/updateReg", this.updateItem).then(() => {
        this.$alert("Berhasil Registrasi Ulang", "Success", "success");
        this.dialog = false;
        this.$router.push("/user");
      });
    }
  }
};
</script>
