<template>
  <v-card class="mx-auto" max-width="400">
    <v-img class="red--text align-end" height="200px" :src="buktiBayar">
      <v-card-title class="align-center" v-if="buktiBayar === null"
        >Blm Ada File Di Upload</v-card-title
      >
      <v-card-title>Last Update {{ lasttime }}</v-card-title>
    </v-img>
    <v-card-text class="text--primary">
      <input type="file" id="fileReg" ref="file" @change="fileBukti" />
    </v-card-text>
    <v-card-actions>
      <v-btn color="orange" text @click="uploadBuktiReg">
        Upload File
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Bayar",
  data: () => ({
    //
    fileBuktiReg: null
  }),
  props: {
    buktiBayar: String,
    lasttime: String
  },
  computed: {
    ...mapGetters("auth", ["userInfo"])
  },
  methods: {
    fileBukti(e) {
      this.fileBuktiReg = e.target.files[0];
      console.log(e);
    },
    uploadBuktiReg() {
      let formData = new FormData();
      formData.append("file", this.fileBuktiReg);
      formData.append("id_pst", this.userInfo.id);
      this.$store.dispatch("daftar/updateBuktiReg", formData).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    }
  }
};
</script>
