<template>
  <v-stepper v-model="e6" vertical>
    <v-stepper-step :complete="e6 > 1" step="1">
      Upload Bukti Bayar
      <small>Summarize if needed</small>
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-card color="grey lighten-1" class="mb-4 mx-auto">
        <v-card-text>
          <bayar
            :buktiBayar="getPstByid.bukti_reg"
            :lasttime="getPstByid.last_update_bukti_reg"
          />
        </v-card-text>
      </v-card>
      <v-btn
        color="primary"
        @click="e6 = 2"
        v-show="getPstByid.bukti_reg !== null"
      >
        Continue
      </v-btn>
    </v-stepper-content>
    <v-stepper-step :complete="e6 > 2" step="2">
      Registrasi Ulang
    </v-stepper-step>
    <v-stepper-content step="2">
      <v-card color="grey lighten-1" class="mb-12">
        <v-responsive :aspect-ratio="16 / 9">
          <reg />
        </v-responsive>
      </v-card>
      <v-btn color="primary" @click="e6 = 1">
        Continue
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>
<script>
import reg from "../components/RegistrasiUlang.vue";
import bayar from "../components/Bayar.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      e6: 1
    };
  },
  components: {
    reg,
    bayar
  },
  computed: {
    ...mapGetters("daftar", ["getPstByid"]),
    ...mapGetters("auth", ["userInfo"])
  },
  created() {
    this.$store.dispatch("daftar/loadpst", this.userInfo.id);
  }
};
</script>
